.text-block-component {
    background-color: $color-neutral10;
    max-width: 700px;
    padding: 60px 0;

    .content-heading-title {
        font-size: 32px;
        line-height: 40px;
        max-width: 242px;
        margin: 0 auto;

    }
    // AFFPT-1608
    h6, p, div{
        &.content-heading-title{
            font-weight: 700;
        }
    }
    .heading-description {
        font-size: 18px;
        line-height: 22px;
        h3{
            font-size: unset;
            font-family: unset;
            text-align: unset;
        }
    }

    .heading-text {
        font-size: 20px;
        line-height: 30px;
    }
}
