.testimonials-block {
    padding: 60px 16px;

    a {
        &:hover {
            text-decoration: none;
        }

        .btn-primary {
            height: 56px;
            padding: 19px 48px;
        }
    }

    .swiper-reviews {
        .review-group {
            min-height: 400px;

            @include media-breakpoint-down(sm) {
                min-height: 320px;
            }
        }

        .pet-image {
            img {
                width: 96px;
                height: 96px;
                border-radius: 50%;
            }
        }

        .customer-name {
            font-family: $font-qualion;
            text-transform: uppercase;
            font-size: 24px;
            color: $color-darkGreen;
            font-weight: 700;
            line-height: 36px;
            padding-top: 16px;
            text-align: unset;
            font-size: unset;
            margin: unset;
        }

        .section-title {
            font-family: $font-qualion;
            font-size: 18px;
            color: $color-neutral80;
            font-weight: 700;
            line-height: 26px;
            padding-top: 8;
            font-size: unset;
            padding: unset;
            margin: unset;
            text-align: left;
        }

        .customer-review {
            padding: unset;
            margin: unset;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            display: -webkit-box;

            &.expanded {
                -webkit-line-clamp: 20;
            }
        }

        .read-more-button,
        .read-less-button {
            font-size: 14px;
            font-weight: 700;
            line-height: 20px;
            color: $color-darkGreen;
            padding-top: 16px;
            text-decoration: underline;
            cursor: pointer;
        }

        .reviews-pagination {
            gap: 20px;

            .button-next,
            .button-prev {
                cursor: pointer;
            }

            .swiper-pagination-bullet {
                background: none;
            }

            .swiper-pagination-clickable .swiper-pagination-bullet {
                margin: 0 10px;
                display: inline-flex;
                justify-content: center;
                align-items: center;
                opacity: 1;
                width: 32px;
                height: 32px;
                border-radius: 4px;
                font-size: 16px;
            }

            .swiper-pagination-bullet:not(.swiper-pagination-bullet-active) {
                &:hover {
                    background: $color-neutral40;
                }
            }

            .swiper-pagination-bullet-active {
                margin: 0 10px;
                color: $white;
                background: $color-darkGreen;
                font-weight: 700;

                &:hover {
                    background: $color-darkGreen;
                }
            }
        }
    }
}
