%selectBase {
  border-radius: $border-radius-base;
  height: 44px;
  border: 1px solid map-get($colors, "neutral20");
  padding: 12px;
  font-size: 14px;
  line-height: 44px;
  font-weight: 400;
  color: map-get($colors, "neutral80");
  width: 100%;
}
%selectFeedbackBase {
  font-size: 14px;
  line-height: 20px;
}
