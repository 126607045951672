.background-container {
    background: #FBF5EC;
    width: 100vw;

    .ingredients-wrapper {
        display: flex;
        width: 100%;
        padding: 40px 4px 40px 16px;
        margin-top: 20px;
        flex-direction: column;
        align-items: center;
        gap: 24px;
    
        .headline {
            color: #0B2D21;
            text-align: center;
            font-family: $font-qualion;
            font-size: 32px;
            font-style: normal;
            font-weight: 400;
            line-height: 100%;
        }
    
        .cards-container {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            gap: 12px;
    
            > .experience-component {
                border-radius: 20px;
                background: #FFF;
                padding: 8px;
                width: calc(50% - 12px);
    
                .ingredient-card {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    padding: 0;
                    height: 100%;

                    .card-content {
                        flex: 1;

                        .image-wrapper {
                            img {
                                width: 100%;
                                cursor: pointer;

                                .hover-image {
                                    border-radius: 20px;
                                }
                            }
                        }
            
                        .text-wrapper {
                            color: #000;
                            font-family: $font-qualion;
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 20px;
                            padding-bottom: 20px;
        
                            .headline {
                                color: var(--Primary-800, #00271C);
                                font-size: 16px;
                                font-weight: 700;
                                line-height: 24px;
                                text-align: left;
                            }
        
                            .benefits {
                                display: flex;
                                flex-direction: column;
                                align-items: flex-start;
                                gap: 12px;
        
                                .benefit {
                                    display: flex;
                                    align-items: flex-start;
                                    gap: 8px;
                                    align-self: stretch;
        
                                    img {
                                        padding-top: 4px;
                                    }
                                }
                            }
                        }
                    }

                    .cta-button-wrapper {
                        .styled-button {
                            display: flex;
                            align-items: center;
                            gap: 8px;
                            background-color: #F8F4E6;
                            color: #042D21;
                            border: none;
                            padding: 10px 16px;
                            border-radius: 40px;
                            font-size: 16px;
                            font-weight: 500;
                            cursor: pointer;
                            outline: none;
                        }
                        
                        .styled-button .button-text {
                            font-family: inherit;
                            text-transform: initial;
                        }
                        
                        .styled-button .button-icon {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            background-color: #CDE3D3;
                            border-radius: 50%;
                            width: 28px;
                            height: 28px;
                        }
                        
                        .styled-button .button-icon {
                            svg {
                                margin-left: 0px;

                                path {
                                    fill: #042D21;
                                }
                            }
                        }
                    }
                }
            }
        } 
    }
}

@include media-breakpoint-up(lg) {
    .background-container {
        .ingredients-wrapper {
            padding: 80px;
            gap: 64px;
            max-width: 1280px;
            margin: 0 auto;

            .headline {
                font-size: 48px;
            }

            .cards-container {
                gap: 24px;

                > .experience-component {
                    padding: 20px;
                    width: calc(25% - 24px);

                    .ingredient-card {
                        .card-content {
                            .image-wrapper {
                                display: flex;
                                justify-content: center;

                                img {
                                    width: 100%;
                                    border-radius: 20px;
                                    height: 215px;
                                }
                            }

                            .text-wrapper {
                                font-size: 14px;
                                line-height: 20px;
                
                                .headline {
                                    font-size: 20px;
                                    line-height: 30px;
                                }
                            }
                        }

                        .cta-button-wrapper {
                            width: 100%;

                            .styled-button {
                                width: 100%;
                                display: flex;
                                justify-content: space-between;
                                position: relative;

                                .button-text {
                                    position: absolute;
                                    left: 50%;
                                    transform: translateX(-50%);
                                }

                                .button-icon {
                                    margin-left: auto;
                                }
                            }
                        }
                    }
                }
            } 
        }
    }
}