.pd-text-component-without-icon {
    display: grid;
    padding: 2rem;
    &__content {
        .pd-text-component-without-icon__title {
            color: $color-darkGreen;
            text-align: unset;
            font-family: unset;
        }
        // AFFPT-1608
        // Keeping same distance title-main text with div and p
        p, div{
            &.pd-text-component-without-icon__title {
                margin-bottom: 0.5rem;
            }
        }
        .pd-text-component-without-icon__text {
            color: $color-neutral80;
            margin-bottom: 0;
        }

    }
}
