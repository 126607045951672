.pd-bulletpoints {
    padding: 20px 0;

    .point-wrapper {
        padding-bottom: 24px;

        .point-image {
            width: 50px;
            height: 50px;
        }
    }
    &__title {
        font-family: $font-qualion;
    }
}
