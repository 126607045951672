.content-benefits {
    padding: 60px 0;

    .container {
        max-width: 935px;
    }

    .benefits-heading-title {
        line-height: 40px;
        max-width: 242px;
        margin: 0 auto;
    }
    h1.benefits-group-title{
        font-size: unset;
    }
    h1.benefits-group-title{
        font-size: unset;
    }

    .benefits-group {
        img {
            width: 128px;
            height: 129px;
        }

        .benefits-group-title {
            text-transform: uppercase;
            font-weight: 700;
            line-height: 26px;
            font-size: 18px;
            color: $color-darkGreen;
            margin-bottom: 0;
            padding: 16px 0;
            font-family: $font-qualion;
            text-align: unset;
        }

        .plain-text {
            font-size: 20px;
            line-height: 30px;
            color: $color-neutral80;
        }
    }

    .shop-link {
        &:hover {
            text-decoration: none;
        }

        .btn-primary {
            padding: 19px 99px;
            height: 56px;
        }
    }
}
