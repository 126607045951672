@import "../custom/variables/variables";
$breakpoint-name: 'md';
$breakpoint-name: 'md' !default;
$breakpoint-index: index(map-keys($grid-breakpoints), $breakpoint-name);
$prev-breakpoint: nth(map-keys($grid-breakpoints), $breakpoint-index - 1);
$next-breakpoint: nth(map-keys($grid-breakpoints), $breakpoint-index + 1);

$slide-out-animation: left 0.5s cubic-bezier(0, 1, 0.5, 1);

@mixin caret-left() {
    border-top: 0.3em solid transparent;
    border-bottom: 0.3em solid transparent;
    border-right: 0.3em solid;
    border-left: 0.3 solid transparent;
    width: 0;
    height: 0;
    display: inline-block;
    margin-bottom: 0.125em;
}

@mixin caret-right() {
    border-top: 0.3em solid transparent;
    border-bottom: 0.3em solid transparent;
    border-left: 0.3em solid;
    position: absolute;
    right: 0.3em;
    margin-top: 0.55em;
}

@each $size in map-keys($grid-breakpoints) {

    @include media-breakpoint-down($size) {
        .menu-toggleable-left.navbar-toggleable-#{$size} {
            position: fixed;
            left: -100%;
            top: 12.8%;
            bottom: 0;
            transition: $slide-out-animation;
            display: block;
            max-width: 100%;

            &.in {
                width: 100%;
                left: 0;
                overflow-y: auto;
                overflow-x: hidden;
            }
        }
    }
}

* {
    font-family: $font-qualion;
}

.navbar-nav {
    gap: 24px;
}

.page:has(.dropdown-menu.show) {
    &::before {
        content: "";
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.6);
        z-index: 3;
    }
} 

.navbar.bg-inverse {
    background-color: transparent !important;
    padding: 0;

    @include media-breakpoint-down($breakpoint-name) {
        .menu-group {
            width: 100%;
        }
    }

    @include media-breakpoint-up($next-breakpoint) {
        .navbar-nav .nav-item + .nav-item {
            margin-left: 0;
        }
    }
}

.navbar-expand-md .navbar-nav .nav-link {
    @include media-breakpoint-up(md) {
        padding: 15px 0;
    }
}

.navbar-expand-md .navbar-nav.nav-center {
    justify-content: center;
}

.navbar-expand-md .navbar-nav.nav-spaced {
    justify-content: space-evenly;
}

.navbar-expand-md .navbar-nav.nav-right {
    justify-content: end;
}

@include media-breakpoint-up(lg) {
    .nav-item.underlined {
        &::after {
            content: "";
            position: absolute;
            bottom: 2px;
            left: 0;
            height: 26px;
            width: 100%;
            border-top: solid 12px $color-gold;
            z-index: -1;
            transform: rotateZ(-5deg);
            clip-path: inset(0 100% 0 0);
            transition: clip-path 0.2s ease-in-out;
        }

        .menu-item-chevron-icon {
            transition: transform 0.2s ease-in-out;
        }

        &:hover,
        &:focus,
        &.visited-submenu-link {
            position: relative;
            cursor: pointer;

            &::after {
                clip-path: inset(0);
            }

            .menu-item-chevron-icon {
                transform: rotate(180deg);
            }
        }
    }
}

@include media-breakpoint-up($next-breakpoint) {
    .nav-item > .nav-link {
        color: $color-darkGreen70;
        font-family: $font-qualion;
        text-transform: capitalize;
        font-size: 16px;
        font-weight: 800;
        line-height: 24px;
    }
}

.main-menu.menu-toggleable-left {

    @include media-breakpoint-down($breakpoint-name) {
        background-color: $white;
        z-index: 4;
    }
}

.menu-toggleable-left {
    .close-menu {
        padding: 15px;
        background-color: $color-neutral10;
        flex: 0 0 100%;

        @include media-breakpoint-up($next-breakpoint) {
            display: none;
        }
    }

    li > .close-menu {
        margin-right: 0;
        margin-top: -0.6rem;
        margin-left: 0;
    }

    @include media-breakpoint-down($breakpoint-name) {
        .bg-inverse {
            background-color: white !important;
            color: $grey7;
        }
    }

    &.in {

        @include media-breakpoint-down($prev-breakpoint) {
            right: 0;
        }

        @include media-breakpoint-down($breakpoint-name) {
            .nav-item + .nav-item {
                border-top: 1px solid $grey2;
            }

            .dropdown {
                display: block;
                position: static;
            }

            .dropdown-toggle {
                padding-left: 1rem;
            }

            .show > .dropdown-menu {
                left: 0;
            }

            .dropdown-menu {
                position: absolute;
                left: -100%;
                top: 0;
                width: 100%;
                height: 100%;
                border: 0 none;
                transition: $slide-out-animation;
                display: block;
            }
        }
    }
}

.multilevel-dropdown {
    .dropdown-menu {
        top: 90%;
        border: 0;
        border-radius: 0;

        li {
            &:hover {
                text-decoration: underline $color-gold;
                text-underline-offset: 5px;
                text-decoration-thickness: 2px;
            }
        }

    }

    .dropdown-menu > .dropdown > .dropdown-menu {

        @include media-breakpoint-up($next-breakpoint) {
            top: -0.65em;
            left: 99%;
        }
    }

    .navbar > .close-menu > .back {
        display: none;
    }

    .close-menu .back {
        .caret-left {
            @include caret-left();
        }
    }

    .dropdown-item {
        margin-bottom: 24px;
        padding: 0;

        &:hover {
            background: none;
        }

        .dropdown-link {
            color: $color-darkGreen;
            font-weight: 700;
            font-size: 20px;
            line-height: 30px;
            cursor: pointer;
            border-bottom: 2px solid transparent;
            border-radius: 4px;
            padding: 12px 8px;
            transition: all 0.2s ease-in-out;

            &:hover {
                text-decoration: none;
                border-color: $color-gold;
                background-color: $color-neutral5;

                .dropdown-menu-asset-link-title {
                    color: $color-lightGreen !important;
                }
            }
        }

        &.top-category {
            font-weight: bold;

            > .nav-link {
                padding-left: 0;
            }
        }
    }



    .dropdown-item.underlined {
        position: relative;
    }

    .dropdown-toggle {
        &::after {
            display: none;
        }
    }

    .nav-item {
        .image-container {
            bottom: 0;
            padding-top: 45px;
        }
    }

    .navbar {
        @include media-breakpoint-down($breakpoint-name) {
            flex-flow: wrap;
        }
    }
}

.mobile-search-block {
    @include media-breakpoint-down(md) {
        background-color: $color-neutral10;
        margin: 0 22px;
        height: 56px;

        .site-search {
            height: auto;
        }

        .close-search-button {
            display: none;
        }

        .search-field,
        .header-search-button {
            height: 35px;
        }
    }
}

.close-menu {
    svg {
        color: $color-darkGreen
    }
}

.mobile-nav-item {
    display: flex;
    justify-content: space-between;
}

.rotate-arrow-icon {
    .menu-item-chevron-icon {
        transform: rotate(180deg);
    }
}

.desktop-nav {
    .nav-item {
        position: relative;
    }

    .dropdown-item {
        position: relative;
    }
}

.mobile-nav {
    padding: 16px 32px 24px 32px;

    .nav-mobile-menu {
        padding: 0;

        ul {
            padding: 0;
        }

        .underlined-link {
            &:after {
                left: -25px;
                bottom: 10px;
            }
        }

        .promotional-slot-wrapper {
            gap: 10px;
            margin-bottom: 12px;
            margin-top: 12px;

            @include media-breakpoint-down(sm) {
                gap: 0;
                margin-bottom: 0;
                margin-top: 0;
            }
        }
        
        .menu-dropdown-image-wrapper {
            gap: 16px;

            @include media-breakpoint-down(md) {
                gap: 24px;
            }

            @include media-breakpoint-down(sm) {
                gap: 16px;
            }

            .menu-dropdown-image-picture {
                border-radius: 16px;
                width: 100%;
                height: 172px;
                object-fit: cover;

                @include media-breakpoint-down(md) {
                    height: 140px;
                }

                @include media-breakpoint-down(sm) {
                    height: 172px;
                }
            }

            .menu-dropdown-image-label {
                color: $color-white;
                font-family: $font-qualion;
                font-size: 18px;
                font-weight: 700;
                line-height: 26px;

                @include media-breakpoint-down(md) {
                    font-size: 24px;
                    line-height: 36px;
                }

                @include media-breakpoint-down(sm) {
                    font-size: 18px;
                    line-height: 26px;
                }
            }

            .menu-dropdown-image-button {
                padding: 16px 24px;
                gap: 16px;
                border-radius: 4px;
                background: $color-white;
                color: $color-darkGreen70;
                font-family: $font-qualion;
                font-size: 16px;
                font-weight: 700;
                line-height: 24px;
            }
        }

        .menu-promotional-banner {
            padding: 20px 32px;
            gap: 16px;
            background-color: $color-gold;
            flex-direction: column;
            margin-bottom: 16px;

            @include media-breakpoint-down(md) {
                margin-bottom: 0;
                height: 100%;
            }

            @include media-breakpoint-down(sm) {
                margin-bottom: 16px;
                height: auto;
            }

            .menu-promotional-banner-label {
                padding: 2px 8px;
                border-radius: 4px;
                background: $color-white;
                color: $color-darkGreen70;
                font-family: $font-qualion;
                font-size: 12px;
                font-weight: 700;
                line-height: 18px;
                width: fit-content;
            }

            .menu-promotional-banner-text-wrapper {
                @include media-breakpoint-down(md) {
                    max-width: 282px;
                    height: 100%;
                }

                @include media-breakpoint-down(sm) {
                    max-width: auto;
                    height: auto;
                }
            }

            .menu-promotional-banner-text {
                color: $color-black;
                font-family: $font-qualion;
                font-size: 18px;
                font-weight: 700;
                line-height: 26px;
            }
        }

        .dropdown-menu-asset-links-wrapper {
            list-style-type: none;
            padding: 0 20px;
            border-right: 1px solid $color-neutral30;
            flex: 1 1 0;
        }

        .desktop-menu-category-type {
            padding: 0px 8px;
            color: $color-neutral80;
            font-family: $font-qualion;
            font-size: 14px;
            font-weight: 700;
            line-height: 24px;
            text-decoration: none;
            cursor: default;
        }

        .dropdown-menu-asset-links-container {
            gap: 12px;
            flex-wrap: wrap;
        }

        .dropdown-menu-asset-item {
            display: flex;
            text-decoration: none !important;

            .dropdown-menu-asset-link {
                .dropdown-menu-asset-link-title {
                    color: $color-darkGreen70;
                    font-family: $font-qualion;
                    font-size: 16px;
                    font-weight: 800;
                    line-height: 24px;
                    white-space: normal;
                }

                .dropdown-menu-asset-link-description {
                    color: $color-neutral60;
                    font-family: $font-qualion;
                    font-size: 12px;
                    font-weight: 700;
                    line-height: 18px;
                    white-space: normal;
                }
            }
        }
    }

    .nav-item {
        border-top: 1px solid $color-neutral30;
        padding: 22px 12px 22px 0;

        @include media-breakpoint-down(md) {
            &.first {
                border-top: 0;
            }
        }

        @include media-breakpoint-down(sm) {
            &.first {
                border-top: 1px solid $color-neutral30;
            }
        }

        .nav-link {
            color: $color-darkGreen;
            font-size: 18px;
            font-weight: 700;
            line-height: 26px;
            text-transform: capitalize;
            padding: 0;
            cursor: pointer;
        }

        .mobile-nav-link {
            color: $color-darkGreen70;
            font-size: 18px;
            font-weight: 700;
            line-height: 26px;
            text-transform: capitalize;
            cursor: pointer;
            &:hover {
                text-decoration: none;
            }
        }
    }

    .mobile-menu-category-type {
        list-style-type: none;
        padding: 0px 8px;
        color: $color-neutral80;
        font-family: $font-qualion;
        font-size: 14px;
        font-weight: 700;
        line-height: 24px;
    }

    .mobile-menu-link {
        list-style-type: none;
        padding: 12px 8px;

        svg {
            color: $color-black;
        }

        .dropdown-menu-asset-link-title {
            color: $color-darkGreen70;
            font-family: $font-qualion;
            font-size: 16px;
            font-weight: 800;
            line-height: 24px;
        }

        .dropdown-menu-asset-link-description {
            color: $color-neutral60;
            font-family: $font-qualion;
            font-size: 12px;
            font-weight: 700;
            line-height: 18px;
        }
    }

    .dropdown-menu-asset-image-wrapper {
        width: 100%;
        background-color: $color-darkGreen70;
        padding: 32px 0px;
        margin-top: 20px;
        margin-bottom: 16px;

        .dropdown-menu-asset-image {
            width: 182px;
            height: 218px;
        }
    }

    .dropdown-link {
        color: $color-darkGreen;

        &:hover {
            text-decoration: none;
        }
    }

    .underlined-link {
        position: relative;
        text-decoration: underline $color-gold;
        text-underline-offset: 5px;
        text-decoration-thickness: 2px;
    }
}

.account-mobile-icon {
    width: 24px;
    height: 24px;
    margin-right: 18px;
}

.account-menu-mobile {
    .account-menu-button {
        height: 56px;
        border-bottom: 1px solid $color-neutral10;
        padding-left: 24px !important;
        padding-right: 24px !important;
        display: flex;
        flex-direction: row;
        align-items: center;
        color: $color-darkGreen;

        .account-menu-name {
            font-family: $font-qualion;
            font-weight: 700;
            font-size: $text-01--mobile;
            line-height: 20px;
            color: $color-darkGreen;
            text-transform: uppercase;
        }

        .arrows {
            position: absolute;
            right: 24px;
            color: $color-darkGreen;

            > svg {
                display: none;
                width: 16px;
                height: 16px;

                &.active {
                    display: block;
                }
            }
        }
    }

    .account-menu-content {
        .account-menu-item {
            height: 44px;
            padding: 0 24px 0 24px;
            display: flex;
            align-items: center;
            font-family: $font-qualion;
            font-weight: 700;
            font-size: $text-02--mobile;

            a {
                text-decoration: none;
                color: $color-darkGreen;
                &:hover {
                    color: $color-lightGreen;
                }
            }

            &.logout {
                align-items: flex-end;
                border-bottom: 1px solid $color-neutral10;
                padding: 10px 24px 15px 24px;

                a {
                    font-size: $text-01--mobile;
                    text-decoration: underline;
                }
            }
        }
    }

    .underlined-link {
        &::after {
            left: -3px;
            bottom: 10px;
        }
    }
}

.navbar-expand-md .navbar-nav .dropdown-menu {
    @include media-breakpoint-up(lg) {
        position: absolute;
        left: 0;
        top: 100%;
        width: 85vw;
        min-width: 40%;
        height: auto;
        padding: 16px 0 0 0;
        background-color: transparent;

        &.show {
            display: flex;
        }

        .dropdown-menu-asset-links {
            padding: 16px 20px;
            background-color: $color-white;
            border-top-left-radius: 8px;
            border-bottom-left-radius: 8px;
            flex-wrap: wrap;

            .dropdown-menu-asset-links-wrapper {
                list-style-type: none;
                padding: 0 20px;
                border-right: 1px solid $color-neutral30;
                flex: 1 1 0;
            }

            .desktop-menu-category-type {
                padding: 0px 8px;
                color: $color-neutral80;
                font-family: $font-qualion;
                font-size: 14px;
                font-weight: 700;
                line-height: 24px;
                text-decoration: none;
                cursor: default;
            }

            &.category {
                padding: 24px 0;
                border-bottom-left-radius: 0;
            }

            .dropdown-menu-asset-item {
                display: flex;
                text-decoration: none !important;

                .dropdown-menu-asset-link {

                    .dropdown-menu-asset-link-container {
                        .dropdown-menu-asset-link-title,
                        .dropdown-menu-asset-link-description {
                            width: 288px;
                        }
                    }

                    .dropdown-menu-asset-link-title {
                        color: $color-darkGreen70;
                        font-family: $font-qualion;
                        font-size: 16px;
                        font-weight: 800;
                        line-height: 24px;
                        max-width: 226px;
                        white-space: normal;
                    }

                    .dropdown-menu-asset-link-description {
                        color: $color-neutral60;
                        font-family: $font-qualion;
                        font-size: 12px;
                        font-weight: 700;
                        line-height: 18px;
                        max-width: 226px;
                        white-space: normal;
                    }
                }
            }
        }

        .dropdown-menu-asset-image-wrapper {
            width: 320px;
            background-color: $color-darkGreen70;
            border-top-right-radius: 8px;
            border-bottom-right-radius: 8px;

            .dropdown-menu-asset-image {
                width: 182px;
                height: 218px;
            }
        }

        .menu-dropdown-image-wrapper {
            gap: 16px;

            .menu-dropdown-image-picture {
                border-radius: 16px;
                width: 272px;
                height: 172px;
            }

            .menu-dropdown-image-label {
                color: $color-white;
                font-family: $font-qualion;
                font-size: 18px;
                font-weight: 700;
                line-height: 26px;
            }

            .menu-dropdown-image-button {
                padding: 16px 24px;
                gap: 16px;
                border-radius: 4px;
                background: $color-white;
                color: $color-darkGreen70;
                font-family: $font-qualion;
                font-size: 16px;
                font-weight: 700;
                line-height: 24px;
            }
        }

        .menu-promotional-banner {
            padding: 20px 32px;
            gap: 16px;
            background-color: $color-gold;
            border-bottom-left-radius: 8px;

            .menu-promotional-banner-label {
                padding: 2px 8px;
                border-radius: 4px;
                background: $color-white;
                color: $color-darkGreen70;
                font-family: $font-qualion;
                font-size: 12px;
                font-weight: 700;
                line-height: 18px;
            }

            .menu-promotional-banner-text {
                color: $color-black;
                font-family: $font-qualion;
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;

                .bold {
                    font-weight: 700;
                }
            }
        }
    }


    ul {
        padding: 0;
    }
}

.header-banner:not(.d-none) ~ .header-main-wrapper {
    .navbar-expand-md .navbar-nav .dropdown-menu {
        overflow: auto;

        /* customization for scrollbar */
        /* width */
        &::-webkit-scrollbar {
            width: 7px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
            box-shadow: inset 0 0 5px $color-white;
            border-radius: 3px;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: $color-neutral20;
            border-radius: 3px;
            background-clip: padding-box;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
            background: $color-neutral30;
        }

        &::-webkit-scrollbar-button {
            width: 0;
            height: 30px;
            display: block;
        }
    }
}

.header-banner:not(.d-none) ~ .header-main-wrapper.active {
    .navbar-expand-md .navbar-nav .dropdown-menu {
        @include media-breakpoint-up(lg) {
            top: 50px;
            padding-right: 20px;
        }
    }
}

.mobile-search-icon {
    color: $color-darkGreen70;

    .search-message {
        color: $color-darkGreen70;
        font-family: $font-qualion;
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        text-transform: capitalize;
    }

    svg {
        color: $color-darkGreen70;
    }
}

.content-asset {
    .collapse,
    .collapsing {
        padding: 0;

        @include media-breakpoint-down(md) {
            padding: 0 20px;
        }

        @include media-breakpoint-down(sm) {
            padding: 0;
        }
    }
}
