.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    overflow-y: auto;
    transition: opacity 0.3s ease;
    opacity: 0;
    pointer-events: none;

    .headline {
        color: var(--Primary-Green-Dark, #00573F);
        font-size: 18px;
        font-weight: 700;
        line-height: 16px;
    }

    .description-wrapper {
        display: flex;
        width: 100%;
        padding: 40px 20px;
        flex-direction: column;
        align-items: center;
        gap: 24px;
        border-radius: 40px;
        background: #FBF5EC;

        .image-wrapper img {
            border-radius: 24px;
            width: 100%;
        }

        .subtext {
            color: #000;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
        }
    }

    .overlay-main-region {
        margin-top: 50px;

        .composition-wrapper {
            display: flex;
            flex-direction: column;
            gap: 19px;
            margin-bottom: 60px;

            p {
                margin: 0;
            }

            .headline {
                color: #0B2D21;
                font-size: 22px;
                font-weight: 400;
                line-height: 100%;
            }

            .cards-container {
                display: flex;
                flex-wrap: wrap;
                gap: 8px;

                > div {
                    width: calc(33% - 5px);
                    margin-bottom: 24px;
                    display: flex;
                }

                .analytical-constituent-card {
                    padding: 20px;
                    border-radius: 15px;
                    text-align: center;
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    flex-grow: 1;
                    font-size: 16px;
                    font-weight: bold;

                    .nutrition-title {
                        display: block;
                        margin-bottom: 8px;
                    }

                    .nutrition-divider {
                        border: none;
                        border-top: 1px solid #FFFFFF;
                        margin: 8px 0; 
                    }
                }
            }

            &.text-cards {
                gap: 32px;

                .cards-container {
                    gap: 20px;
                    justify-content: space-between;

                    > div {
                        width: calc(50% - 10px);
                        max-width: 300px;
                    }
                }
            }
        }

        .experience-commerce_assets-ctaButton {
            display: flex;
            justify-content: center;
        }

        .pd-cta-button-wrapper {
            background-color: inherit;
            padding: 0;
            max-width: 450px;
            flex-direction: column;

            a {
                text-transform: none;
            }
        }
    }
}

.no-scroll {
    position: fixed;
    overflow: hidden;
}

.overlay.active {
    opacity: 1;
    pointer-events: auto;
}

.overlay-content {
    background: $color-neutral10;;
    border-radius: 20px 20px 0 0;
    padding: 30px;
    position: relative;
    width: 100%;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
    margin-top: 40px;
    overflow-y: auto;
    max-width: 807px;

    .headline-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;

        p {
            margin-bottom: 0;
        }

        .close-button {
            background: none;
            border: none;
            font-size: 20px;
            color: #333;

            &:focus {
                outline: 0;
            }

            svg {
                background: #FFFFFF;
                border-radius: 22px;
                padding: 8px;
                width: 40px;
                height: 40px;
            }
        }
    }
}

@include media-breakpoint-up(lg) {
    .overlay {
        padding: 0;
        justify-content: flex-end;

        .description-wrapper {
            flex-direction: row-reverse;
            gap: 32px;
            padding: 40px;
            align-items: flex-start;

            .image-wrapper {
                width: 50%;
                justify-content: center;
            }

            .subtext {
                width: 50%;
            }
        }

        .overlay-content {
            max-width: 807px;
            border-radius: 0;
            margin-top: 0;
            background: $white;

            .headline-wrapper {
                .close-button svg {
                    background: #FBF5EC;
                }
            }

            .headline {
                color: #0B2D21;
                font-size: 48px;
                font-weight: 400;
                line-height: 100%;
            }
        
            .pd-feefo {
                padding-left: 10px;
                place-content: flex-start;
                background: $white;
            }

            .overlay-main-region {
                .composition-wrapper {
                    gap: 32px;
                    margin-bottom: 80px;

                    .headline {
                        font-size: 40px;
                    }

                    .subtext {
                        font-size: 20px;
                        line-height: 30px;
                    }

                    .cards-container {
                        justify-content: flex-start;

                        > div {
                            width: calc(25% - 15px);
                        }
                    }
                }
            }
        }
    }
}


