@import "menu";
@import "../custom/variables/variables";

$banner-padding: 0.3125em;
$menu-padding: 0.5em;
$menu-item-margin: 0.625em;

.header {
    position: relative;
    height: 100%;
}

.header-main-wrapper {
    position: sticky;
    top: -1px;
    height: 76px;
    z-index: 100;
    background-color: $color-white;
    transition: background-color 0.3s ease-in-out;

    @include media-breakpoint-down(md) {
        height: 56px;
    }

    .container {
        max-width: 1400px;
        padding-left: 20px;
        padding-right: 20px;

        @include media-breakpoint-down(md) {
            padding-left: 16px;
            padding-right: 16px;
        }
    }
}

.mobile-menu-block {
    @include media-breakpoint-down(md) {
        display: flex;
        justify-content: start;
    }

    .navbar-toggler {
        color: $color-darkGreen;
        padding: 0;
        outline: none;
    }
}

.header-main-wrapper.active {

    @include media-breakpoint-up(lg) {
        background-color: $color-white;
        transition: background-color 0.3s ease-in-out;

        .brand {
            .logo-img {
                width: 103px;
                height: 64px;
                margin-top: -5px;
                transition: all 0.3s ease-in-out;
            }
        }
    }

    @include media-breakpoint-down(md) {
        background-color: $color-header-background;
        transition: background-color 0.3s ease-in-out;

        .menu-toggleable-left {
            top: 7.8%;
        }
    }
}

.navbar-header {
    .user,
    .country-selector,
    .search,
    .minicart,
    .minicart-mobile {
        display: inline-block;
        margin: 1.125em 0 0 0.5em;
    }

    .user,
    .country-selector,
    .minicart,
    .navbar-toggler {
        line-height: 2.25em; /* 36/16 */
        height: auto;
    }

    .navbar-toggler {
        font-size: 1.6em;
        width: auto;
    }

    .user {
        position: relative;

        .popover {
            position: absolute;
            display: none;
            padding: 1em;
            top: 85%;
            left: 0;

            a {
                white-space: nowrap;
                margin-bottom: 0.5em;
            }

            &::before {
                left: 1.5rem;
            }

            &::after {
                left: 1.5rem;
            }

            &.show {
                display: block;
            }
        }
    }
}

.brand-container {
    position: relative;
    top: 16px;
}

.brand {
    display: block;
    text-align: center;
    position: relative;
    z-index: 20;

    .logo-img {
        @include media-breakpoint-down(md) {
            width: 103px;
            height: 64px;
            transition: all 0.3s ease-in-out;
        }

        @include media-breakpoint-up(md) {
            width: 115px;
            height: 75px;
        }

        @include media-breakpoint-up(lg) {
            width: 131px;
            height: 87px;
            transition: all 0.3s ease-in-out;
        }
    }
}

.header-navigation {
    font-size: 16px;
    @include media-breakpoint-down(lg) {
        font-size: 14px;
    }
    @include media-breakpoint-up(lg) {
        padding: 15px 0;
    }
}

.nav-icons-content {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    @include media-breakpoint-down(sm) {
        flex-direction: row-reverse;
    }

    .user-icon {
        @include media-breakpoint-down(sm) {
            display: none;
        }

        &:hover {
            opacity: 0.5;
        }

        a {
            &:hover {
                text-decoration: none;
            }
        }

        svg {
            cursor: pointer;
            color: $color-darkGreen;
        }
    }

    .not-logged-user {
        padding: 0 16px;
    }

    .logged-user {
        padding: 0 10px;
        @include media-breakpoint-down(lg) {
            padding: 0 10px 0 5px;
        }
    }

    .search-icon,
    .location-icon {
        padding: 0;
        color: $color-darkGreen;

        &:hover {
            opacity: 0.5;
        }

        svg {
            color: $color-darkGreen;

            &:hover {
                cursor: pointer;
            }
        }
    }

    .search-icon {
        display: flex;
        cursor: pointer;
        @include media-breakpoint-down(sm) {
            display: none;
        }
    }

    .cart-not-empty {
        color: $white;
    }

    .user-message,
    .search-message {
        font-size: 16px;
        line-height: 24px;
        font-weight: 700;
        color: $color-darkGreen;
        padding-left: 10px;
        text-transform: capitalize;
    }
}

.header-login-btn {
    .mealplan-label,
    .mealplan-short-label,
    .header-login-label,
    .abandoned-mealplan-label {
        @include media-breakpoint-down(lg) {
            font-size: 14px;
        }
    }
}

.mealplan-label {
    @include media-breakpoint-down(sm) {
        display: none;
    }
}

.mealplan-short-label {
    display: none;
    @include media-breakpoint-down(sm) {
        display: inline;
    }
}

.mealplan-btn {
    .mealplan-btn-link {
        padding: 10px 24px 10px 16px;
        gap: 12px;
        border-radius: 4px;
        background: $color-darkGreen70;
        color: $color-white;
        font-family: $font-qualion;
        font-size: 16px;
        font-weight: 700;
        line-height: 16px;
        text-transform: none;
        height: 44px;

        @include media-breakpoint-down(sm) {
            padding: 10px 16px;
            font-size: 14px;
            height: 40px;
        }

        &:hover {
            background-color: $color-lightGreen;
        }

        svg {
            path {
                stroke: $color-white;
            }
        }
    }

    @include media-breakpoint-down(lg) {
        padding: 0;
    }
    @include media-breakpoint-down(sm) {
        padding: 0 0 0 15px;
    }
}

.header-login-btn {
    padding: 10px 24px;
    border-radius: 4px;
    border: 2px solid $color-darkGreen70;
    color: $color-darkGreen70;
    font-family: $font-qualion;
    font-size: 16px;
    font-weight: 700;
    line-height: 16px;
    text-transform: none;

    @include media-breakpoint-down(sm) {
        padding: 0 0 0 15px;
    }
}

.mealplan-btn-mobile-menu,
.not-logged-user-mobile-menu {
    padding: 3px 0 0 0;

    .login-btn-mobile-menu {
        padding: 10px 24px;
        border-radius: 4px;
        border: 2px solid $color-darkGreen70;
        color: $color-darkGreen70;
        font-family: $font-qualion;
        font-size: 16px;
        font-weight: 700;
        line-height: 16px;
        width: 167px;
    }
}

.main-menu-container {
    max-width: 55%;
}

.main-menu {

    .navbar .close-menu button,
    .navbar .close-button button {
        background-color: transparent;
        border: 0;
        -webkit-appearance: none;
    }
}

.header-banner {
    background-color: var(--headerBannerBackground, $color-darkGreen);
    color: $color-white;
    font-size: 14px;
    line-height: 20px;
    padding: 8px 0;
    position: relative;
    z-index: 20;

    .container-fluid {
        @include media-breakpoint-up(lg) {
            max-width: 832px;
        }

        @include media-breakpoint-up(xl) {
            max-width: 942px;
        }
    }

    .top-promo-icon {
        margin-right: 6px;
        white-space: nowrap;
        font-size: 0;
    }

    .content {
        width: 100%;
    }

    .close-button {
        width: 1.5em + $banner-padding * 2;
        position: absolute;
        right: 15px;
        top: 10px;

        @include media-breakpoint-up(md) {
            top: 5px;
        }


        .close {
            opacity: 1;
            color: $color-white;
            width: 100%;
            height: 100%;

            &:focus,
            &:active {
                outline: none;
            }

            svg {
                width: 15px;
                height: 15px;
            }
        }
    }

    .slick-arrow {
        position: absolute;
        width: 20px;
        height: 20px;
        font-size: 0;
        border: none;
        outline: none;
        border-radius: 50%;
        background-color: $color-white;
        background-image: url('../../img/chevron-right.png');
        background-size: 7px auto;
        background-repeat: no-repeat;
        background-position: 8px 4px;
        transform-origin: center;
        top: 50%;
        margin: -10px 0 0 0;
        transform: translate(0);

        &:hover {
            opacity: 0.75;
        }

        &::before {
            display: none;
        }

        &.slick-prev {
            transform: rotate(180deg);
            left: -35px;
        }

        &.slick-next {
            right: -35px;
        }
    }

    .header-promotion {
        font-size: 14px;
        font-weight: 700;
        line-height: 20px;
        display: flex;
        align-items: flex-start;
        padding: 4px 25px 4px 0;

        @include media-breakpoint-up(lg) {
            justify-content: center;
        }

        span,
        a {
            line-height: 20px;
        }

        span {
            display: block;

            @include media-breakpoint-up(sm) {
                display: inline;
            }
        }

        a {
            color: $color-white;
            text-decoration: underline;
            position: relative;
            padding-right: 10px;
            display: inline-block;
            margin-top: 6px;
            padding-right: 15px;
            text-underline-offset: 5px;

            @include media-breakpoint-up(sm) {
                margin-top: 0;
                margin-left: 20px;
            }

            &::after {
                content: "";
                width: 10px;
                height: 12px;
                display: block;
                position: absolute;
                background-image: url('../../img/arrow-right-white.svg');
                background-repeat: no-repeat;
                background-position: right center;
                right: 0;
                top: 4px;
            }

            &:hover {
                text-decoration: none;
                color: $color-successGreen;

                &::after {
                    background-image: url('../../img/arrow-right-green.svg');
                }
            }
        }
    }
}

.menu-tablet {
    text-transform: capitalize;
    margin-left: 5px;
    color: $color-darkGreen;
    @include media-breakpoint-down(md) {
        display: none;
    }
}

.minicart,
.minicart-mobile {
    vertical-align: top;

    &:hover {
        text-decoration: none;
    }

    .minicart-icon {
        width: 24px;
        height: 24px;
        margin-right: 10px;

        @include media-breakpoint-down(md) {
            width: 20px;
            height: 20px;
        }
    }

    .cart-empty {
        color: $color-darkGreen;
    }

    .cart-not-empty {
        color: $color-darkGreen;
    }

    .minicart-link {
        &:hover {
            text-decoration: none;
        }
    }

    .minicart-total {
        @include media-breakpoint-down(md) {
            &.over-100 {
                .minicart-icon {
                    width: 17px;
                    height: 17px;
                    margin-right: 5px;
                }

                .minicart-quantity {
                    font-size: 16px;
                }
            }
            &.over-1000 {
                .minicart-icon {
                    width: 15px;
                    height: 15px;
                    margin-right: 5px;
                }

                .minicart-quantity {
                    font-size: 14px;
                }
            }
        }
    }
}

.minicart-background.active {
    @include media-breakpoint-down(xs) {
        display: none;
    }
}

a.normal {
    color: #{var(--skin-primary-color-1)};
    text-decoration: underline;
}

.slide-up {
    transition-duration: 0.5s;
    transition-timing-function: ease-in;
    overflow: hidden;

    &.hide {
        max-height: 0;
        padding-top: 0;
        padding-bottom: 0;
        margin-top: 0;
        margin-bottom: 0;
        transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    }
}

.dropdown-country-selector {
    margin-top: -0.0625em;
}

.cookie-warning-messaging.cookie-warning {
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
    text-align: center;
    display: none;
}

.valid-cookie-warning {
    background-color: #{var(--skin-link-color-1)};
    color: $white;
    white-space: nowrap;

    p {
        margin-top: 0;
        margin-bottom: 0.2em;
        padding-right: 2em;
    }
}

.search-wrapper {
    background-color: $color-header-background;

    .search-header-title {
        color: $color-darkGreen;
        text-transform: uppercase;
        font-size: 40px;
        line-height: 44px;
        width: 100%;
        padding: 34px 0;
    }

    .search-logo {
        padding-top: 16px;
    }
}

.search-block-content {
    position: sticky;
    z-index: 100;
    top: 0;
    height: 100vh;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
}
.search-block-content::-webkit-scrollbar {
    display: none;
}

.suggestions-content {
    background-color: $color-header-background;
    height: 60px;
}

.suggestions-background {
    width: 100%;
    height: 100vh;
    background-color: $white;
}

.suggestions-wrapper {

    @include media-breakpoint-down(md) {
        padding: 0;
    }

    .suggestions {
        display: flex;
        flex-direction: row;

        @include media-breakpoint-down(md) {
            flex-direction: column;
            position: absolute;
            overflow-y: scroll;
            left: 0;
            top: 22px;
            width: 100%;

            > :last-child {
                padding-bottom: 32px;
            }
        }


        @include media-breakpoint-up(md) {
            padding-top: 32px;
            padding-left: 15px;
        }

        > div {
            display: flex;
            flex-direction: column;
            width: 100%;
            max-width: 100%;
            overflow-y: scroll;
            height: 345px;
            position: relative;

            @include media-breakpoint-up(lg) {
                flex-direction: initial;
                overflow-y: initial;
            }
        }

        .suggestion-section-header {
            align-items: baseline;
            margin-top: 16px;

            @include media-breakpoint-up(md) {
                margin-bottom: 24px;
            }

            @include media-breakpoint-down(md) {
                padding-left: 16px;
                padding-right: 16px;
            }

            h3 {
                font-family: $font-qualion;
                font-weight: 700;
                font-size: $text-02--desktop;
                line-height: 24px;
                color: $color-neutral40v2;
                text-transform: uppercase;
                margin-bottom: 0;
                margin-right: 24px;
            }

            a {
                font-family: $font-qualion;
                font-weight: 700;
                font-size: $text-01--desktop;
                line-height: 20px;
                color: $color-darkGreen;
                text-decoration: underline;
                cursor: pointer;
            }
        }

        .product {
            @include media-breakpoint-down(md) {
                padding-left: 16px;
                padding-right: 16px;
                border-bottom: 1px solid $color-neutral20;
            }

            .product-tile {
                border-bottom: 0 !important;

                .tile-body {
                    .info-content,
                    .price {
                        position: inherit;
                    }
                }

                .tile-small-feefo-add-to-cart {
                    display: none;
                }

                .available-bundles {
                    display: none;
                }
            }
        }
    }
}
