.tab{
  align-content: center;
  background-color: $color-neutral40;
  border: 1px solid $color-neutral20;
  cursor: pointer;
  display: grid;
  height: 100%;
  justify-content: center;
  place-content: center;
  width: 100%;
  &.disabled{
    background-color: $color-neutral10 !important;
    border-color: $color-neutral20;
    border-radius: $border-radius-base;
    color: $color-neutral30 !important;
    *{
      color: $color-neutral30 !important;
      font-weight: 400;
    }
    .tab-line{
      background-color: transparent;
    }
  }
  &.tab-left{
    border-radius: $border-radius-base 0 0 $border-radius-base;
    &.disabled{
      border-right: 0;
    }
  }
  &.tab-center{
    border-radius: 0;
    &.disabled{
      border-right: 0;
      border-left: 0;
    }
  }
  &.tab-right{
    border-radius: 0 $border-radius-base $border-radius-base 0;
    &.disabled{
      border-left: 0;
    }
  }
  &.selected{
    background-color: $color-white;
    .tab-line{
      background-color: $color-darkGreen;
    }
  }
  .tab-line{
    height: 3px;
    max-height: 3px;
    width: 20%;
    line-height: 3px;
    background-color: transparent;
    position: absolute;
    bottom: -1px;
    left: 40%;
    border-radius: 48px 48px 0px 0px;
  }
  .tab-title{
    color: $color-darkGreen;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
  }
  .tab-text{
    color: $color-black;
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
  }
}
.row.no-gutters {
  margin-right: 0;
  margin-left: 0;

  & > [class^="col-"],
  & > [class*=" col-"] {
    padding-right: 0;
    padding-left: 0;
  }
}
