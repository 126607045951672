#recipes {
    display: flex;
    justify-content: center;
    background-color: $color-white;
    margin-top: 20px;

    .container-full {
        max-width: 100%;
    }
}

.frozen-nutrients-wrapper {
    .custom-component-container {
        display: flex;
        margin: 72px 16px 0;
        padding: 24px;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 70px;
        align-self: stretch;
        border-radius: 20px;
        background: #FBF5EC;
    
        .custom-image-wrapper {
            position: relative;
            width: 100%;
        }
        
        .custom-image {
            display: block;
            width: 100%;
            max-width: 100%;
            border-radius: 15px;
        }
        
        .custom-text-wrapper {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 42px;
            align-self: stretch;
        }
        
        .custom-headline {
            color: #0B2D21;
            font-family: $font-qualion;
            font-size: 32px;
            font-style: normal;
            font-weight: 400;
            line-height: 100%; /* 32px */
        }
        
        .custom-subtext {
            color: var(--LG---Dark-green, #042D21);
            font-family: $font-qualion;
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: 30px; 
            margin-bottom: 0px;
        }
    }
}

/* Desktop styles */
@include media-breakpoint-up(lg) {
    .frozen-nutrients-wrapper {
        padding: 0 80px;

        .custom-component-container {
            flex-direction: row;
            align-items: center;
            padding: 64px 40px;
            margin: 160px auto;
            max-width: 1280px;
    
            .custom-headline {
                font-size: 40px;
            }
        
            .custom-subtext {
                font-size: 20px;
            }
        }
    } 
}
