.alert {
    &.alert-sm {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 4px 8px;
        gap: 8px;
        width: 100%;
        height: fit-content;
        border-radius: 4px;

        &.alert-error {
            background: $color-error-light;
            border: 1px solid #FCBDBD;
            color: $color-error;
        }

        &.alert-info {
            background: $color-info-light;
            border: 1px solid $color-info-blue;
            color: $color-info;
        }

        &.alert-success {
            background: $color-success-light;
            border: 1px solid #9FD39E;
            color: $color-success;
        }

        &.alert-warn {
            background: $color-warn-light;
            border: 1px solid $color-warn-orange;
            color: $color-warn;
        }

        svg {
            width: 14px;
            height: 56px;
            margin-right: 0.5rem;
        }
    }

    &.alert-lg {
        border-radius: 4px;
        font-size: 14px;
        line-height: 20px;
        height: fit-content;
        padding: 1rem;

        &.alert-error {
            background-color: $color-error-light;
            border: 1px solid #FCBDBD;
            color: $color-error;

            svg {
                color: $color-error;
            }
        }

        &.alert-info {
            background: $color-info-light;
            border: 1px solid $color-info-blue;
            color: $color-info;

            svg {
                color: $color-info;
            }
        }

        &.alert-success {
            background: $color-success-light;
            border: 1px solid #9FD39E;
            color: $color-success;

            svg {
                color: $color-success;
            }
        }

        &.alert-warn, &.alert-danger {
            background: $color-warn-light;
            border: 1px solid $color-warn-orange;
            color: $color-warn;

            svg {
                color: $color-warn;
            }
        }

        &.floating {
            box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
        }

        svg {
            width: 20px;
            height: 20px;
            margin-right: 1rem;
        }
    }

    .alert-text {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        width: 100%;
        height: min-content;

        @include media-breakpoint-down(sm) {
            font-size: 12px;
            line-height: 18px;
        }
    }

    &.flash-messages {
        width: fit-content !important;
    }
}

.alert-danger {
    background-color: $color-warn-light;
}

.alert-success {
    background-color: $color-success-light;
}

.alert-danger,
.alert-success {
    color: $color-black;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    border-radius: 4px;
}
