////////////////////////////////
// NATURES MENU CUSTOM UI KIT //
////////////////////////////////
@import "variables/variables";
@import "utils/mixins/mixins";
@import "utils/placeholders/placeholders";
@import "components/components";

*{
  font-family: $font-qualion;
}
.ui-kit-container{
  background: $color-background;
}
