.storepage:has(.experience-commerce_layouts-buttonsGrid3),
.storepage:has(.experience-commerce_assets-textBlock) {
    .pd-content-simple-banner {
        min-height: 456px;
    }
}

.buttons-content {
    gap: 20px;

    @include media-breakpoint-down(md){
        gap: 0;
    }

    @include media-breakpoint-up(lg){
        margin-top: -128px;
    }

    .region {
        margin: 0;

        @include media-breakpoint-down(md){
            width: 100%;
        }
    }

    .content-group {
        width: 220px;
        height: 128px;
        text-align: center;
        @include media-breakpoint-down(md){
            width: 100%;
            height: auto;
        }

        .icon-component {
            margin: 0;
            padding-top: 16px;
        }
    }
}


