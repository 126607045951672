.farm-ingredients {
    padding:0 16px;
    margin-top: 72px;

    .wrapper {
        .headline {
            margin-bottom: 44px;
            color: #0B2D21;
            text-align: center;
            font-family: $font-qualion;
            font-size: 32px;
            font-style: normal;
            font-weight: 400;
            line-height: 100%; /* 32px */
        }
        .column-container {
            display: flex;
            flex-direction: column-reverse;
            gap: 20px;
    
            .region {
                padding: 0;
    
                .farm-ingredients-text {
                    margin-bottom: 20px;
                    color: var(--LG---Dark-green, #042D21);
                    font-family: $font-qualion;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 30px; /* 150% */
                }
        
                .photo-tile-image {
                    border-radius: 20px;
                }
            }
    
            .region-1 {
                .pd-cta-button-wrapper {
                    padding-left: 0;
                    margin-top: 20px;

                    .pd-cta-button {
                        text-transform: none;
                    }
                }
            }
        }
    }
}

/* Desktop styles */
@include media-breakpoint-up(lg) {
    .farm-ingredients {
        padding:0 80px;

        .wrapper {
            max-width: 1280px;
            margin: 160px auto;

            .headline {
                margin-bottom: 94px;
            }
    
            .column-container {
                flex-direction: row;
                flex-wrap: nowrap;
                gap: 0;
    
                .region-1 {
                    padding-right: 32px;
    
                    .pd-cta-button-wrapper {
                        margin-top: 32px;
                    }
                }
    
                .region-2 {
                    padding-left: 32px;
                }
            }
        }    
    }
}