.pd-content-simple-banner{
    min-height: 358px;
    display: grid;
    place-content: center;
    text-align: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    object-fit:cover;
    @include media-breakpoint-down(md) {
        min-height: 442px;
    }
    &__breadcrumbs{
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        position: absolute;
        top: 48px;
        left: 6.25%;
        .breadcrumbs-chevron{
            padding: 0 4px 0 4px;
        }
        .breadcrumbs-folder{
            font-weight: 700;
        }
    }
    &__content {
        max-width: 600px;

        .pd-content-simple-banner__title {
            font-family: $font-qualion;
            line-height: 67px;
        }

        .pd-content-simple-banner__text {
            font-weight: 400;
            line-height: 26px;
        }

        @include media-breakpoint-down(md) {
            max-width: 327px;
            .pd-content-simple-banner__title {
                font-size: 32px;
                line-height: 38px;
            }
        }
    }
}
