@import "variables";
@import "experienceGlobal";
@import "../custom/UIKit";
@import "../custom/components/swiper";
@import "../components/header";
@import "components/commerceAssets/contentSimpleBanner";
@import "components/commerceAssets/textComponentWithoutIcon";
@import "components/commerceAssets/textComponentWithIcon";
@import "components/commerceAssets/contentReviewSimple";
@import "components/commerceAssets/contentPetInfo";
@import "components/commerceAssets/buttonsContent";
@import "components/commerceAssets/ctaBlockComponent";
@import "components/commerceAssets/textBlock";
@import "components/commerceAssets/contentBenefits";
@import "components/commerceAssets/bulletPoints";
@import "components/commerceAssets/ctaButton";
@import "components/commerceAssets/onTheMenu";
@import "components/commerceAssets/frozenNutrients";
@import "components/commerceAssets/recipesBanner";
@import "components/commerceAssets/farmIngredients";
@import "components/commerceAssets/nutrients";
@import "components/commerceAssets/ingredientCards";
@import "components/commerceAssets/recipeOverlay";
@import "components/commerceLayouts/testimonialCarousel";

.storepage {
    width: 100%;
    overflow-x: hidden !important;
    overflow-y: hidden !important;
}

.main-slider-component {
    .swiper-pagination-bullet {
        background: transparent;
        border: white solid 1px;
        opacity: 1;

        &-active {
            background: white;
        }
    }
}

.pd-hero-banner {
    background-size: cover;
    background-position-x: center;
    background-repeat: no-repeat;
    height: 713px;
    position: relative;
    .pd-hero-banner-custom-icon{
        width: 20px;
        margin-left: 20px;
    }
    .pd-hero-banner-video {
        height: inherit;
        position: relative;
        overflow: hidden;

        iframe {
            z-index: 1;
            width: 100vw;
            height: 56.25vw; /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
            min-height: 100vh;
            min-width: 177.77vh; /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            @include media-breakpoint-down(md) {
                min-width: 180vh;
            }
        }

        &__mask {
            z-index: 2;
            height: 100%;
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
        }
    }

    @include media-breakpoint-down(sm) {
        height: 660px;
    }

    .pd-hero-banner-callout {
        z-index: 3;
        position: absolute;
        top: 19%;
        margin-left: 14%;

        p {
            margin-bottom: 6px;
        }

        @include media-breakpoint-down(sm) {
            margin-left: 7.75% !important;
            margin-right: 20% !important;
        }
    }

    .pd-hero-banner-content__left {
        z-index: 3;
        margin-left: 13.75%;
        max-width: 586px;
        position: absolute;
        top: 28%;

        @include media-breakpoint-down(sm) {
            margin-left: 7.75% !important;
            margin-right: 20% !important;
        }

        .pd-hero-banner-title__text {
            line-height: 90%;
            margin: 0;
            user-select: none;

            @include media-breakpoint-down(sm) {
                font-size: 32px !important;
                line-height: 38px !important;
            }
        }
        // Added in AFFPT-1608
        // Needed to avoid h3 going to the center
        // as it is inheriting styles
        h3.pd-hero-banner-title__text{
            text-align: unset;
        }
        .pd-hero-banner-button {
            margin-top: 5%;
            display: flex;

            button svg {
                overflow: visible;
                margin-left: 20px !important;
            }

            @include media-breakpoint-down(sm) {
                .btn {
                    font-size: 16px !important;
                    height: 44px !important;
                }
            }
        }
    }
}

.pd-general-information {
    padding: 60px 13.5%;

    @include media-breakpoint-down(sm) {
        padding: 60px 16px;
    }

    .pd-general-information-row {
        height: 100%;
    }

    &__title
    &__text {
        font-size: 40px;
        line-height: 48px;
        margin: 0;

        @include media-breakpoint-down(sm) {
            font-size: 32px;
            line-height: 38px;
        }
    }
    // Adjustments made to the title so it matches the style once the tag
    // selected changes
    h3.pd-general-information__text{
        text-align: unset;
    }
    p.pd-general-information__text, div.pd-general-information__text{
       font-weight: 700;
    }
    &__content {
        display: flex;
        justify-content: space-between;
        flex-direction: column;

        .row {
            height: 45%;
        }

        .pd-general-information__content-item {
            align-items: center;
            display: flex;
            flex-direction: column;
            padding: 0 18.5px 0 18.5px;
            justify-content: space-between;

            img {
                top: 0;
                width: auto;
                height: 70%;
                margin-bottom: 24px;

                @include media-breakpoint-down(sm) {
                    margin-left: 25px;
                    margin-bottom: 0;
                }
            }

            h1, h2, h3, h4, h5, h6, p, div {
                font-weight: 400;
                font-family: 'qualion';
                text-align: center;
                font-size: 20px;
                line-height: 24px;
                height: 30%;
                margin: 0;

                @include media-breakpoint-down(sm) {
                    text-align: left;
                    font-size: 16px;
                    line-height: 24px;
                }
            }
        }
    }
}

.pd-video-information {
    background-color: $color-darkGreen80;
    padding: 5% 8%;
    position: relative;

    @include media-breakpoint-down(sm) {
        padding: 30px;
    }

    &__video {
        width: 60%;
        height: 500px;

        @include media-breakpoint-down(md) {
            height: 200px;
            width: 100%;
        }
    }

    &__content {
        color: white;
        width: 30%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        margin-left: -100px;
        z-index: 99;
        pointer-events: none;

        @include media-breakpoint-down(md) {
            margin-left: 0;
            padding: 10px;
            margin-top: -30px;
            width: 100%;
        }

        .pd-video-information__title {
            font-size: 56px;
            margin: 0 0 24px 0;
            line-height: 67.2px;
            font-weight: 700;

            @include media-breakpoint-down(md) {
                width: 100%;
                font-size: 32px;
                line-height: 38px;
            }
        }
        // AFFPT-1608
        h1, h2, h3, h4, h5, h6 {
            font-family: unset;
        }

        .pd-video-information__text {
            font-size: 20px;
            line-height: 30px;
            width: 85%;
            margin: 0 0 0 auto;

            @include media-breakpoint-down(md) {
                margin: 0;
                width: 100%;
                font-size: 16px;
                line-height: 24px;
            }
        }
    }
}

.pd-best-food {
    padding: 5% 10% 0 10%;
    background-color: $color-neutral10;

    svg {
        margin: 10px 0;
    }

    .pd-best-food__head p, .pd-best-food__head h1 , .pd-best-food__head h2, .pd-best-food__head h3,
    .pd-best-food__head h4 , .pd-best-food__head h5, .pd-best-food__head h6, .pd-best-food__head div {
        .pd-best-food__title-container {
            text-align: center;
            width: 100% !important;
            max-width: 330px;
            margin: 3rem auto;
            transform: rotate(-5deg);
            padding: 0 !important;
            @include media-breakpoint-down(sm) {
                max-width: 80%;
            }

            .heading-wrapper {
                padding: 15px 25px 20px 25px;
                margin-bottom: 0;
            }
        }
        .pd-best-food__title-container:has(.pd-best-food__title-container) {
            .heading-wrapper {
                padding: 15px 25px 0px 25px;
            }
        }
        @include media-breakpoint-down(sm) {
            max-width: 100%;
        }

        &.pd-best-food__title {
            line-height: 40px;
            color: $color-darkGreen;
            margin: 0;

            @include media-breakpoint-down(sm) {
                font-size: 28px !important;
                line-height: 30px;
            }
        }

        &.pd-best-food__subTitle {
            line-height: 26px;
            color: $color-lightGreen;
            margin: 0;
            @include media-breakpoint-down(sm) {
                font-size: 22px !important;
            }
        }
    }

    .pd-best-food__item {
        background-size: 100%;
        background-position: center;
        background-repeat: no-repeat;
        border-radius: 8px;
        margin: 1.5% .75% 0 .75%;
        min-height: 600px;
        max-width: 600px;
        transition: all 0.5s ease-in-out;
        -webkit-transition: all 0.5s ease-in-out;
        position: relative;

        @include media-breakpoint-down(xxl) {
            min-height: 400px;
        }

        @include media-breakpoint-down(md) {
            min-height: 340px;
        }

        @include media-breakpoint-down(sm) {
            min-height: 150px;
        }

        &::after {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            background-color: black;
            opacity: 0.3;
            transition: .5s;
            border-radius: 8px;
        }

        .pd-best-food__text {
            font-family: $font-qualion;
            font-size: 40px;
            line-height: 48px;
            color: white;
            font-weight: 700;
            z-index: 2;
            user-select: none;

            @include media-breakpoint-down(sm) {
                font-size: 24px;
                line-height: 36px;
            }
        }

        &:hover {
            background-size: 110%;
            cursor: pointer;

            &::after {
                opacity: 0;
            }
        }
    }
}

.pd-blog-layout {

    &__title {
        padding: 5.5% 0 2% 0;

        @include media-breakpoint-down(sm) {
            padding-top: 10%;
        }

        p, h2, span {
            font-family: $font-qualion;
            margin: 0;
            line-height: 40px;

            @include media-breakpoint-down(sm) {
                font-size: 24px;
                line-height: 36px;
            }

            &.pd-blog-layout__main-title {
                color: $color-darkGreen;
            }

            &.pd-blog-layout__sub-title {
                color: $color-lightGreen;
            }
        }
    }

    .pd-blog-wrapper {
        padding: 0 13% 4% 13%;

        @include media-breakpoint-down(sm) {
            padding: 0 4% 4% 4%;
        }

        .pd-blog-content {
            @include media-breakpoint-down(sm) {
                margin-bottom: 20px;
            }

            img {
                border-radius: 4px;
                -webkit-transition: -webkit-transform 0.5s ease-in-out;
                transition: transform 0.5s ease-in-out;
            }

            p {
                margin: 1rem 0;
            }

            .pd-read-more {
                width: min-content;
                white-space: nowrap;
                color: $color-lightGreen;
                font-weight: 700;
                visibility: hidden;

                @include media-breakpoint-down(md) {
                    visibility: visible;
                    margin: 10px 0;
                }
            }

            &:hover {
                img {
                    -webkit-transform: rotate(-1.5deg);
                    transform: rotate(-1.5deg);
                    -webkit-box-shadow: 0px 6px 5px $color-shadow;
                    box-shadow: 0px 6px 5px $color-shadow;
                }

                .pd-read-more {
                    visibility: visible;
                }
            }
        }
    }
}
// Fix for naturesmenu_blogcontent_title component
h1, h2, h3, h4, h5, h6, div {
    &.pd-blog-content__text {
        font-size: unset;
        font-family: $font-qualion;
        font-weight: unset;
        line-height: 24px;
        margin: 0 0 1rem 0;
    }
}
.pd-feefo {
    border-radius: 4px;
    padding: 0 13% 3% 13%;
    background-color: $color-neutral10;
    place-content: center;
    &__section {
        @include media-breakpoint-down(md) {
            height: auto;
        }

        > div {
            flex-basis: 100%;
        }
    }
}

.pd-general-information-2:not(.content-treats) {
    height: 950px;
    @include media-breakpoint-down(md) {
        height: auto;
    }
}

.pd-general-information-2 {
    border-radius: 4px;
    padding: 3% 13% 3% 13%;

    @include media-breakpoint-down(md) {
        padding: 0;
        height: auto;
    }

    &__section-text {
        height: 40%;
        padding: 0 15px;

        @include media-breakpoint-down(md) {
            height: auto;
        }

        > div {
            flex-basis: 100%;
        }

        .pd-general-information__textarea {
            &--1 {
                padding: 5% 5% 5% 5%;
                border-radius: 0 4px 4px 0;
                height: 100%;
                max-height: 374px;
                @include media-breakpoint-down(md) {
                    border-radius: 0;
                }

                a {
                    width: max-content;
                }

                @include media-breakpoint-down(md) {
                    padding: 30px 5% 30px 5%;

                    .btn {
                        font-size: 16px !important;
                        height: 44px !important;
                    }
                }
                // Added in AFFPT-1608
                // This kind of unset properties are added after
                // the title selector was added in the components.
                // Some of the styles are overridden and we need to keep
                // the original behavior..
                h3.pd-general-information-2__title {
                    text-align: unset;
                }

                .pd-general-information-2__title {
                    font-family: $font-qualion;
                    font-size: 40px;
                    line-height: 48px;
                    font-weight: 700;
                    color: $color-darkGreen;

                    @include media-breakpoint-down(md) {
                        font-size: 24px;
                        line-height: 36px;
                    }
                }

                .pd-general-information-2__text {
                    font-size: 18px;
                    line-height: 26px;
                    flex-grow: 1;

                    @include media-breakpoint-down(md) {
                        font-size: 16px;
                        line-height: 24px;
                    }
                }
            }

            &--2 {
                padding: 3% 5% 5% 5%;
                background-color: $color-darkGreen;
                height: 100%;

                a {
                    width: max-content;
                }

                @include media-breakpoint-down(md) {
                    padding: 30px 5% 30px 5%;

                    .btn {
                        font-size: 16px !important;
                        height: 44px !important;
                    }
                }

                .pd-general-information-2__title {
                    font-family: $font-qualion;
                    font-size: 40px;
                    line-height: 48px;
                    font-weight: 700;
                    color: white;

                    @include media-breakpoint-down(md) {
                        font-size: 24px;
                        line-height: 36px;
                    }
                }

                .pd-general-information-2__text {
                    font-size: 18px;
                    line-height: 26px;
                    flex-grow: 1;
                    color: white;

                    @include media-breakpoint-down(md) {
                        font-size: 16px;
                        line-height: 24px;
                    }
                }
            }
        }

        .pd-general-information-2__image {
            background-size: cover;
            background-position-x: center;
            background-repeat: no-repeat;
            padding: 0 !important;
            border-radius: 4px 0 0 0;
            img {
                height: 100%;
                width: 100%;
                max-height: 374px;
                max-width: 596px;
                float: right;
            }
        }
    }

    &__section-misc {
        height: 20%;
        padding: 0 15px;

        @include media-breakpoint-down(md) {
            height: auto;
        }

        > div {
            flex-basis: 100%;
        }

        .pd-general-information-2__stockist {
            background-color: $color-lightGreen;

            @include media-breakpoint-down(md) {
                min-height: 210px;
            }

            .stockist-title {
                font-weight: unset;
                font-family: $font-qualion;
                color: white;
                line-height: 48px;
                font-size: 40px;
                margin: 0;
                width: 50%;

                @include media-breakpoint-down(md) {
                    font-size: 28px;
                    line-height: 42px;
                }
            }

            &:hover {
                cursor: pointer;
            }
        }

        .pd-general-information-2__faq {
            background-color: $color-gold;

            @include media-breakpoint-down(md) {
                min-height: 210px;
            }

            .faq-title {
                font-weight: unset;
                font-family: $font-qualion;
                color: white;
                line-height: 48px;
                font-size: 40px;
                margin: 0;

                @include media-breakpoint-down(md) {
                    font-size: 28px;
                    line-height: 42px;
                }
            }

            &:hover {
                cursor: pointer;
            }
        }
    }
}

.pd-subscription {
    height: 475px;
    width: 285px;
    display: table-cell;
    vertical-align: middle;
    padding: 30px 25px;
    margin-bottom: 60px;
    border-radius: 4px;
    .pd-subscription__title1 {
        font-weight: bold;
    }
    .pd-subscription__title2 {
        font-weight: bold;
        margin-bottom: 10px;
    }
    .pd-subscription__plain-text {
        margin-bottom: 30px;
    }
    .btn {
        width: 100%;
    }
    // AFFPT-1608
    // Avoiding overrides
    h1, h2, h3, h4, h5, h6, p, div {
        &.pd-subscription__titleTag {
            font-size: unset;
            text-align: unset;
        }
    }
    p, div {
        &.pd-subscription__titleTag {
            margin-bottom: 0.5rem;
        }
    }

    @include media-breakpoint-down(sm) {
        height: 260px;
        width: 100%;
        .pd-subscription__title1 {
            font-size: 24px;
            line-height: 36px;
        }
        .pd-subscription__title1 {
            font-size: 24px;
            line-height: 36px;
        }
        .pd-subscription__plain-text {
            font-size: 16px;
            line-height: 24px;
        }
        .btn {
            font-size: 16px;
            line-height: 16px;
        }
    }
}

.experience-commerce_assets-carouselrecommendation {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}
.pd-carouselrecommendation {
    margin: 0;
    .experience-subscription {
        margin: 0 52px;
        max-width: max-content;
        min-width: max-content;
        padding-bottom: 60px;
    }
    .experience-carousel {
        width: 100%;
        margin-left: 20px;
        margin-right: 80px;
        .carousel-control-prev {
            background-color: unset;
            margin-left: -43px;
            .carousel-control-prev-icon {
                background-image: url('../../img/carousel-prev.svg');
            }
        }
        .carousel-control-next {
            background-color: unset;
            margin-right: -43px;
            .carousel-control-next-icon {
                background-image: url('../../img/carousel-next.svg');
            }
        }
        .carousel-item {
            padding: 0;
        }
        .product-tile {
            border-radius: 4px;
            .new-product-badge {
                left: 0px;
                width: 60px;
                height: 40px;
                @media (min-width: 625px) and (max-width: 680px) {
                    left: 25px;
                }
                @media (min-width: 680px) and (max-width: 775px) {
                    left: 45px;
                }
            }
        }
    }
    .pd-carouselrecommendation__title-container {
        text-align: center;
        width: 100% !important;
        max-width: fit-content;
        margin: 3rem auto;
        transform: rotate(-5deg);
        padding: 0 !important;

        .heading-wrapper {
            padding: 15px 25px 20px 25px;
            margin-bottom: 0;
        }
        .pd-carouselrecommendation {
            &__title, &__subTitle {
                font-family: $font-qualion;
                font-style: normal;
                line-height: 30px;
                text-align: center;
                text-transform: uppercase;
                z-index: 2;
                @include media-breakpoint-down(sm) {
                    font-size: 22px !important;
                }
            }
        }
    }
    .pd-carouselrecommendation__tiles_section {
        display: flex;
    }

    @include media-breakpoint-down(sm) {
        .experience-carousel {
            margin-left: 0px;
            margin-right: 0px;
            width: unset;
            .carousel-inner {
                flex-wrap: unset;
                
                @include media-breakpoint-only(xs) {
                    width: 300%;
                }
                @include media-breakpoint-only(sm) {
                    width: 200%;
                }
                
                &.row {
                    margin-left: -58%;
                    margin-right: 0;
                }
            }
            .carousel-item {
                min-width: auto;
                max-width: unset;
                max-width: 24%;
            }
            .product-tile {
                flex-direction: column;
                height: 455px;
                border-bottom: none !important;
                .image-container {
                    flex-basis: 50%;
                    padding: 8px;
                }
                .tile-feefo {
                    min-height: 26px;
                    .product-number-rating {
                        margin: auto;
                    }
                }
                .tile-image {
                    max-width: 220px;
                }
                .tile-body  {
                    flex-basis: 35%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    @include media-breakpoint-down(md) {
                        position: relative;
                        min-height: 135px;
                        padding-bottom: 30px !important;
                        .info-content {
                            bottom: 0 !important;
                        }
                    }

                    .food-type {
                        font-size: 16px;
                        font-weight: 700;
                        line-height: 24px;
                        letter-spacing: 0em;
                    }
                    .pdp-link {
                        a {
                            font-size: 18px;
                            font-weight: 700;
                            line-height: 26px;
                            letter-spacing: 0em;
                        }
                    }
                    .available-bundles {
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 20px;
                        letter-spacing: 0em;
                    }
                    .tile-price-wrapper-sm {
                        display: flex;
                        .strike-through {
                            line-height: 30px;
                            display: flex;
                            align-items: end;
                            .value {
                                font-size: 16px;
                                font-weight: 700;
                                line-height: 24px;
                                letter-spacing: 0em;
                            }
                        }
                        .sales {
                            .value {
                                font-size: 20px;
                                font-weight: 700;
                                line-height: 30px;
                                letter-spacing: 0em;
                                .price-discount {
                                    font-size: 14px;
                                    font-weight: 700;
                                    line-height: 20px;
                                    letter-spacing: 0em;
                                }
                            }
                        }
                    }
                }
                .tile-small-feefo-add-to-cart {
                    flex-basis: 15%;
                    display: none;
                }
            }
            .tile-add-to-cart {
                left: unset;
                padding: 0;
                max-width: 220px;
            }
        }
        .pd-carouselrecommendation__title-container {
            padding: 16px 0 48px;
            font-size: 32px;
            line-height: 40px;
        }
        .pd-carouselrecommendation__tiles_section {
            flex-direction: column-reverse;
            .experience-subscription {
                margin: 0;
                max-width: unset;
                min-width: unset;
                padding: 0 32px 16px;
            }
        }
        &.background-curved {
            background: radial-gradient(200% 80% at bottom, $color-neutral10 99.5%, $color-darkGreen80);
        }
    }
}

.storepage:has(.experience-commerce_layouts-mobileGrid2r1c) {
    .pd-general-information-2 {
        height: auto;
        padding: 3% 13% 3% 13%;

        @include media-breakpoint-down(md) {
            padding: 0;
        }

        &__section-text {
            height: auto;
        }

        &__image {
            object-fit: cover;
        }

        .pd-general-information__textarea {
            &--1 {
                height: inherit;
            }
        }
    }

    .pd-carouselrecommendation {
        &.background-curved {
            background: none;
        }

        .carousel-control-prev-icon {
            left: 65%;

            @include media-breakpoint-only(md) {
                left: 25%;
            }
        }

        .carousel-control-next-icon {
            right: -65%;

            @include media-breakpoint-only(md) {
                right: 25%;
            }
        }

        .carousel-control-prev-icon,
        .carousel-control-next-icon {
            @include media-breakpoint-only(md) {
                top: 0;
            }
        }

        .product-tile {
            .food-type,
            .pdp-link,
            .available-bundles,
            .tile-price-wrapper-sm {
                @include media-breakpoint-down(sm) {
                    display: flex;
                    justify-content: center;
                    text-align: center;
                }
            }

            .tile-add-to-cart {
                @include media-breakpoint-down(md) {
                    display: none;
                }
            }
        }
    }

    .pd-advice-information {
        img {
            @include media-breakpoint-down(sm) {
                max-width: 45%;
            }
        }
    }
}

.storepage:has(.experience-commerce_layouts-testimonialsCarousel) {
    .pd-feefo {
        padding: 3% 13% 3% 13%;
    }

    .pd-best-food {
        padding: 0% 10% 3% 10%;
    }
}
