.mobile-2r-1c {
    .region {
        padding: 0;
        margin-bottom: 0;
    }
}

.experience-commerce_assets-ctaBlockComponent {
    height: 100%;
}

.cta-block-component {
    background-color: $color-darkGreen;
    padding: 60px;
    height: 100%;
    h3{
        text-align: unset;
    }

    @include media-breakpoint-down(md){
        padding: 20px;
    }

    .reverse-component {
        @include media-breakpoint-down(md){
            display: flex;
            flex-direction: column-reverse;
        }
    }

    .pd-cta-content {
        gap: 32px;

        @include media-breakpoint-down(md){
            gap: 17px;
        }
        h1, h2, h4, h5, h6, p, div {
            font-size: unset;
        }
    }

    .content-title,
    .content-subtitle {
        font-size: 32px;
        font-weight: 700;
        line-height: 38px;

        @include media-breakpoint-down(md){
            font-size: 24px;
            line-height: 29px;
        }
    }

    .pd-image-group {
        gap: 37px;

        .logo-content {
            display: flex;
            flex-direction: column;
            text-align: center;
            align-items: center;
            margin-left: -20px;

            color: $white;
            font-size: 16px;
            font-weight: 700;
            line-height: 24px;

            img {
                width: 31px;
                height: 32px;
                margin-bottom: 4px;
            }
        }
    }

    .logo-component {
        @include media-breakpoint-up(lg) {
            padding-top: 50px;
        }
    }

    .btn-primary {
        margin: 40px 0;
        color: $color-darkGreen;
        height: 56px;
        padding: 24px;

        @include media-breakpoint-down(md){
            width: 100%;
        }

        a {
            &:hover {
                text-decoration: none;
            }
        }
    }
}

.storepage:has(.experience-commerce_layouts-buttonsGrid3),
.storepage:has(.experience-commerce_assets-textBlock),
.storepage:has(.experience-commerce_assets-textComponentWithIcon),
.storepage:has(.experience-commerce_layouts-contentBulletPoints),
.storepage:has(.experience-commerce_assets-ctaBlockComponent) {
    .pd-carouselrecommendation {
        &__title-container {
            padding: 60px 0 20px 0;
            width: 230px;

            @include media-breakpoint-only(md) {
                padding: 20px 0 0 0;
            }
        }

        .experience-carousel {
            .carousel-inner.row {
                margin-left: 30px;
            }

            .carousel-control-prev,
            .carousel-control-next {
                margin-left: 0;
                margin-right: 0;
            }
        }
    }
    .carousel-inner {
        .product-tile {
            min-height: 458px;
            height: 100%;

            @include media-breakpoint-only(md) {
                min-height: 360px;
                margin-bottom: 10px;

                .info-content {
                    bottom: 8px;
                }
            }
        }

        .tile-image {
            max-width: 230px;

            @include media-breakpoint-only(md) {
                max-width: 130px;
            }
        }

        .color-swatches {
            display: none;
        }

        .image-container {
            overflow: inherit;
        }

        .price {
            bottom: 70px;
        }
    }

    .carousel-control-prev,
    .carousel-control-next {
        margin-left: 0;
        margin-right: 0;
    }
}
