.pd-content-pet-info {
    height: 740px;
    @include media-breakpoint-down(md) {
        height: 100%;
    }
    &.expanded {
        height: fit-content;
    }

    .first-section {
        height: 80%;
        @include media-breakpoint-down(xs) {
            margin: 0 auto;
        }
        @include media-breakpoint-down(sm) {
            margin: 0 auto;
            height: 100%;
        }
    }
    .second-section {
        height: 20%;
        @include media-breakpoint-down(sm) {
            display: none;
        }
    }
    .pd-content-pet-info-image {
        text-align: center;
        padding-top: 2rem;
        img {
            width: 95%;
        }
        @include media-breakpoint-up(lg) {
            text-align: right;
            padding-right: 0;
            padding-top: 4%;
            padding-bottom: 4%;
            img {
                width: 75%;
                height: auto;
                max-width: 584px;
            }
        }
    }
    .pd-content-pet-info-text {
        color: $color-white;
        margin-top: -2rem;
        @include media-breakpoint-down(md) {
            margin-top: 30px;
            margin-bottom: 30px;
        }

        .firstLine {
            font-size: 24px;
            line-height: 38px;
            margin-bottom: 0;
            font-weight: 400;
        }
        .secondLine {
            font-size: 32px;
            line-height: 38px;
            font-weight: 700;
            margin-bottom: 0;
        }

        li {
            margin-top: 1rem;
        }
        h3 {
            text-align: unset;
            font-size: unset;
        }
        .pd-content-pet-info-text-intro {
            font-size: 20px;
            line-height: 30px;
            margin-bottom: 1rem;
        }
        .pd-content-pet-info-text-title {
            line-height: 38px;
            font-weight: 700;
            margin-bottom: 1rem;
            display: inline-block;
        }
        .pd-content-pet-info-text-main {
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 7;
            display: -webkit-box;

            @include media-breakpoint-down(md) {
                -webkit-line-clamp: 4;
            }

            &.expanded {
                max-height: none;
                -webkit-line-clamp: inherit;
            }
        }

        .link-gold {
            font-size: 14px;
            line-height: 20px;
            cursor: pointer;
            font-weight: 700;
            margin-top: 1rem !important;
            display: block;
            @include media-breakpoint-up(lg) {
                text-transform: uppercase;
            }
        }
        @include media-breakpoint-up(lg) {

            padding: 4% 10% 4% 4%;
            margin-top: 0;

            .pd-content-pet-info-text-title {
                line-height: 67px;
                font-weight: 700;
                display: inline-block;
                margin-top: 8%;
            }
        }
    }
}
