.pd-text-component-with-icon {
    display: grid;
    padding: 2rem;

    &.center {
        text-align: center;
    }
    &.left {
        float: left;
    }
    &.right {
        float: right;
    }

    .pd-text-component-with-icon__heading {
        text-align: center;
        &_title {
            color: $color-darkGreen;
            font-weight: 700;
            font-size: 32px;
            line-height: 40px;
            // AFFPT-1608
            h1, h2, h3, h4, h5, h6, p, div {
                margin-bottom: 0;
                font-size: unset;
            }
            // It was not taking the font weight
            h6 {
                font-weight: 700;
            }
        }
        &_icon {
            img {
                max-height: 64px;
            }
        }
    }

    &__content {
        text-align: center;
        .pd-text-component-with-icon__text {
            color: $color-darkGreen;
            font-weight: 400;
            font-size: 20px;
            line-height: 30px;
            margin-bottom: 0px;
        }

    }
}


