.breadcrumbs{
    font-weight: 400;
    font-size: 16px !important;
    line-height: 24px;
    color: $color-lightGreen;
    .breadcrumbs-chevron{
        padding: 0 4px 0 4px;
    }
    .breadcrumbs-folder{
        font-weight: 700;
        color: $color-darkGreen;
    }
    p{
        text-transform: none !important;
    }
}
