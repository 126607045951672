.nutrients {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    padding: 72px 16px 40px;
    gap: 20px;

    .headline {
        color: #0B2D21;
        text-align: center;
        font-family: $font-qualion;
        font-size: 32px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%; /* 32px */
    }

    .content-container {
        display: flex;
        flex-direction: column;
        gap: 64px;

        .region-1 {
            display: flex;
            gap: 20px;
            align-items: stretch;
            overflow-x: auto;
    
            > div {
                display: flex;
            }
    
            .nutrient-card {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                width: 300px;
                padding: 20px;
                gap: 20px;
                border-radius: 20px;

                .image-wrapper img {
                    border-radius: 16px;
                }
            
                .custom-headline {
                    color: #0B2D21;
                    font-family: $font-qualion;
                    font-size: 24px;
                    font-style: italic;
                    font-weight: 700;
                    line-height: 36px; /* 150% */
                    margin-bottom: 12px;
                }
            }
        }

        .experience-commerce_assets-ctaButton {
            display: flex;
            justify-content: center;

            .pd-cta-button-wrapper {
                flex-direction: column;
                max-width: 450px;

                a.pd-cta-button {
                    text-transform: none;
                }
            }
        }
    }
}

@include media-breakpoint-up(lg) {
    .nutrients {
        margin: 160px auto;
        max-width: 1280px;
        padding: 0;
        gap: 64px;

        .headline {
            font-size: 48px;
        }
    }
}

