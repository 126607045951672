.on-the-menu-wrapper {
    .text-block {
        color: #0B2D21;
        font-family: $font-qualion;
        font-style: normal;
        border-radius: 20px;
        background: #B8D5AD;
        padding: 40px 24px;
        flex-direction: column;
        align-items: flex-start;
        gap: 20px;
        align-self: stretch;
        position: relative;
        top: -130px;
        margin: 0 16px;

        .headline {
            font-size: 40px;
            font-weight: 400;
            line-height: 36px;
            font-family: inherit;
        }

        .subtext {
            font-size: 20px;
            font-weight: 400;
            line-height: 30px;
        }
    }

    .image-wrapper img {
        width: 100%;
    }
}

@include media-breakpoint-up(lg) {
    .on-the-menu-wrapper {
        position: relative;

        .text-block {
            position: absolute;
            top: 76px;
            left: 80px;
            gap: 32px;
            max-width: 410px;

            .headline {
                line-height: 100%;
            }
        }
    }
}