// We are conditioned by the select2 library
// So we are not styling selects but span elements that fake the selectors
select + .select2 , .form-select + .select2, .custom-select + .select2{
  .select2-selection{
    @extend %selectBase;
    .select2-selection__rendered{
      line-height: 20px;
      padding-left: 0;
    }
  }
}
select, .form-select, .custom-select{
  &.is-invalid{
    &+.select2 .select2-selection{
      border-color: map-get($colors, "error") !important;
    }
  }
  &.is-valid{
    &+.select2 .select2-selection{
      border-color: map-get($colors, "lightGreen");
    }
  }
  &:disabled {
    &+.select2 .select2-selection{
      background-color: map-get($colors, "disabled");
    }
  }
  &:focus{
    &+.select2 .select2-selection{
      border-color: map-get($colors, "neutral50");
      box-shadow: none !important;
    }
  }
  &::placeholder{
    &+.select2 .select2-selection{
      color: map-get($colors, "neutral30");
    }
  }
}
.select2{
  width: 100% !important;
  .select2-selection__arrow{
    right: 9px !important;
    top: 9px !important;
    b{
      border-color: map-get($colors, "black")  transparent transparent transparent !important;
    }
  }
}
.select2-dropdown{
  font-size: 14px;
  .select2-results__option--selected{
    background-color: $color-white !important;
    font-weight: bold;
  }
  .select2-results__option--highlighted{
    background-color: map-get($colors, "neutral40") !important;
    color: map-get($colors, "black") !important;
  }
}
.select2-search{
  display: none;
}
select, .custom-select, .form-control {
  &:active,
  &:focus {
    box-shadow: none;
    outline: none;
    border-color: $color-neutral20;
  }
}
