.pd-content-review-simple{
    display: flex;
    place-content: center;
    text-align: left;
    padding: 2rem 0 2rem 0;
    @include media-breakpoint-down(md){
        flex-direction: column;
    }
    .pd-content-review-simple__content{
        max-width: 447px;
        padding: 0;
        @include media-breakpoint-down(md){
            max-width: 343px;
            margin: 0 auto;
        }
        &.two{
            padding-left: 1rem;
            @include media-breakpoint-down(md){
                padding: 2rem 0 0 0;
            }
        }
    }
    .pd-content-review-simple__avatar{
        img {
            max-height: 96px;
            border-radius: 50% 50%;
        }
    }
    .pd-content-review-simple__name{
        color: $color-neutral80;
        font-weight: 700;
        font-size: 18px;
        line-height: 26px;
        margin-bottom: 8px;
        text-align: unset;
    }
    .pd-content-review-simple__title{
        color: $color-neutral80;
        font-weight: 700;
        font-size: 18px;
        line-height: 26px;
        margin-bottom: 16px;
        text-align: unset;
    }
    .pd-content-review-simple__text{
        color: $color-neutral80;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 0px;
    }

}