.recipes-banner {
        display: flex;
        margin: 32px 16px;
        padding: 40px 20px;
        flex-direction: column;
        align-items: flex-start;
        gap: 5px;
        align-self: stretch;
        border-radius: 40px;
    
        .subtext {
            color: #FFF;
            font-family: $font-qualion;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
        }
}

/* Desktop styles */
@include media-breakpoint-up(lg) {
    .banner-wrapper {
        padding: 0 80px;

        .recipes-banner {
            flex-direction: row;
            align-items: center;
            padding: 40px 64px;
            gap: 40px;
            max-width: 1280px;
            margin: 160px auto;
            border-radius: 20px;

            .subtext {
                font-size: 24px;
                line-height: 36px;
            }
        }
    }
    
}
